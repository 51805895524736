@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
ol,
ul {
  list-style: none;
}
hr {
  border: 0.2px solid #323b4a;
  opacity: 0.2;
}
svg {
  height: 24px;
  width: 24px;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.display {
  display: none;
}

/* Change Autocomplete styles in Chrome- Snippet from css-tricks*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
.field {
  /* border: 1px solid #5289ff; */
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #343b4a inset;
  box-shadow: 0 0 0px 1000px #343b4a inset;
  transition: background-color 5000s ease-in-out 0s;
}

input {
  transition: border 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

input:focus {
  border: 1px solid #1199fa;
}

input:-webkit-autofill {
  border: 1px solid #1199fa;
}
input:autofill {
  border: 1px solid #1199fa;
}

input:not(:placeholder-shown) {
  border: 1px solid #1199fa;
}

input:invalid {
  border: 1px solid #e64b60;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

/* GENERAL STYLES */

.red {
  color: #e64b60;
}

.redBg {
  background-color: #e64b60;
}

.textBold {
  font-weight: 700;
}

.green {
  color: #20bca4;
}

.greenBg {
  background-color: #20bca4;
}

.textMedium {
  font-weight: 600;
}

.light {
  font-weight: 500;
}

.blue {
  color: #1199fa;
}

.blueBg {
  background-color: #1199fa;
}

/* select { */
/* for Firefox */
/* -moz-appearance: none; */
/* for Chrome */
/* -webkit-appearance: none; */
/* } */

/* For IE10 */
/* select::-ms-expand { */
/* display: none; */
/* } */

.hidden {
  line-height: 1em;
  max-height: 1em;
  max-width: 230px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}

.clickable {
  font-weight: 600;
  color: #1199fa;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  text-transform: capitalize;
  box-sizing: border-box;
  width: max-content;
}

input,
select {
  font-family: "Inter";
}

.infot {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #a3a3a3;
}

input {
  color: #fff;
}

.disabled {
  cursor: not-allowed;
  background-color: gray;
  user-select: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
  font-family: "Inter";
  display: grid;
}

.actionButton {
  background: rgba(35, 163, 251, 0.13);
  border-radius: 6px;
  border: none;
  outline: none;
  cursor: pointer;
}

.actionText {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  padding: 0.5rem 1rem;
  color: #1199fa;
}

.button {
  cursor: pointer;
  width: 100%;
  height: 44px;
  font-size: 600;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Inter";
}

.submit {
  display: grid;
  background-color: #1199fa;
  color: white;

 
}

.submit img, .disabled img {
  justify-self: center;
}

.close {
  cursor: pointer;
}

.close:hover {
  stroke: #1199fa;
}

.hidden {
  opacity: 0;
}

.modal {
  opacity: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  position: absolute;
  right: 0;
  height: 100%;
}

* {
  font-family: "Inter" !important;
}

.amWrap div{
  padding: 8px;
  padding-left: 0;

}

/* button {
  display: grid;
height: fit-content;
} */


button img {
  place-self: center;
}

.regis {
  transition: background-color 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
